import { Component } from '@angular/core';
import {SubChangeService} from "../../../data/services/sub-change.service";
import {HupSubscriptionsService} from "../../../data/services/hup-subscriptions.service";
import {TranslationService} from "../../../core/services/translation.service";
import {ActivatedRoute} from "@angular/router";
import {NavigationCommand, NavigationService} from "../../../data/services/navigation.service";
import {LoginService} from "../../../core/components/login/login.service";
import {HupSubscription} from "../../../data/models/subscription.model";
import {SubChange} from "../../../data/models/sub-change.model";
import {BaseDataService} from "../../../core/services/base-data.service";

@Component({
  selector: 'app-sub-change-without-login',
  templateUrl: './sub-change-without-login.component.html',
  styleUrls: ['./sub-change-without-login.component.css']
})
export class SubChangeWithoutLoginComponent {
  isLoadingSub: boolean = false;
  subs: HupSubscription[] = [];
  errorsArr: string[] = [];
  identified: boolean = false;
  noOrdersFound: boolean = false;

  constructor(private hupSubscriptionsService: HupSubscriptionsService,
              private subChangeService: SubChangeService,
              private baseDataService: BaseDataService,
              public  translationService: TranslationService,
              private route: ActivatedRoute,
              private navigationService: NavigationService,
  ) {
    this.route.paramMap.subscribe((params) => {

      const clientNo : number = Number(params.get('clientNo'));
      if(clientNo) {
        this.identified = true;
        this.loadOrdersForClientNo(clientNo);
      }
    });
  }

  loadOrdersForClientNo(clientNo: number): void {
    this.isLoadingSub = true;
    this.subs = [];
    this.noOrdersFound = false;
    if (clientNo && Number(clientNo) > 0) {
      this.hupSubscriptionsService.getSubscriptionsUnauthorized(clientNo).subscribe(orders => {
        this.isLoadingSub = false;
        if (orders.length === 1 && orders[0]) {
          this.onNewSubChange(orders[0]);
        } else if (orders.length > 1) {
          orders.forEach(order => {
            if(
              (order.validDate.validUntil == null || order.validDate.validUntil > new Date())
              && order.backendId > 0
              && !order.offer?.offerType?.ePaper
            ) {
              this.baseDataService.handleUnauthorizedOrder(order);
              if (!order.variantDescription) {
                this.baseDataService.loadBaseData('variantcode').subscribe(
                  (bds) => {
                    bds?.forEach(bd => {
                      if (bd.key === order.variantCode) {
                        order.variantDescription = bd.description;
                      }
                    })
                  }
                );
              }
              this.subs.push(order);
            }
          });

          this.subs.forEach(sub => {
            sub.isLoadingSubChange = true;

            this.subChangeService.getSubChanges(sub.backendId, sub.userBackendId).subscribe({
              next: (res) => {
                sub.subChanges = res;
              },
              error: (error) => {
                sub.isLoadingSubChange = false;
                this.errorsArr.push(this.translationService.getTranslation('error_subchanges_init'));
              },
              complete: () => {
                sub.isLoadingSubChange = false;
              }
            });
          });
        } else {
          this.noOrdersFound = true;
        }
      });
    }
  }

  navigateToIdentification(): void {
    this.navigationService.navigateTo(NavigationCommand.IDENTIFICATION, [NavigationCommand.SUBCHANGE.substring(1)]);
  }

  onNewSubChange(subscription: HupSubscription): void {
    this.navigationService.navigateTo(NavigationCommand.SUBCHANGEWITHOUTLOGINNEW, [subscription.backendId + '', subscription.userBackendId + '', 'true', subscription.productCode, subscription.variantCode]);
  }

}
