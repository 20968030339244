import {Component, OnInit} from '@angular/core';
import {HupSubscriptionsService} from '../../../data/services/hup-subscriptions.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslationService} from '../../../core/services/translation.service';
import {HupSubscription} from '../../../data/models/subscription.model';
import {ConfigService} from "../../../core/services/config.service";
import {NavigationCommand, NavigationService} from "../../../data/services/navigation.service";
import {BaseDataService} from "../../../core/services/base-data.service";
import {BaseData} from "../../../data/models/base-data.model";
import {PaymentService} from "../../../data/services/payment.service";
import {FormOfPayment} from "../../../data/models/formOfPayment.model";

@Component({
  selector: 'app-hup-subscription-detail',
  templateUrl: './hup-subscription-detail.component.html',
  styleUrls: ['./hup-subscription-detail.component.scss']
})
export class HupSubscriptionDetailComponent implements OnInit {
  sub: HupSubscription;
  faqButtonLink: string = null;
  showAdditionalSubscriptionInfos: boolean = true;
  showTerminationButton: boolean = true;
  currentDate = new Date();
  invoiceMethods: BaseData[] = [];
  paymentMethods: BaseData[] = [];
  showBackToOverviewButton: boolean;
  paymentMethodId: FormOfPayment;
  paymentMethodDesc: string = "";
  aggregateSubscription: boolean = false;
  showSequelSubscriptionInAggregateMode: boolean = false;

  constructor(private hupSubscriptionsService: HupSubscriptionsService,
              private route: ActivatedRoute,
              public translationService: TranslationService,
              private configService: ConfigService,
              private router: Router,
              private navigationService: NavigationService,
              private baseData: BaseDataService,
              private paymentService: PaymentService) {

    this.configService.loadConfig('faqButtonLink').subscribe(config => {
      if( config) {
        this.faqButtonLink = config.value;
      }
    });

    this.configService.loadConfig('showAdditionalSubscriptionInfos').subscribe(config => {
      if( config) {
        this.showAdditionalSubscriptionInfos = config.value === '1' || config.value === 'true';
      }
    });

    this.configService.loadConfig('subscription_show_termination_button').subscribe(config => {
      if( config && config.value) {
        this.showTerminationButton = config.value === '1' || config.value === 'true';
      }
    });

    this.configService.loadConfig('show.backToOverView.button').subscribe(config => {
      if( config && config.value) {
        this.showBackToOverviewButton = config.value === '1' || config.value === 'true';
      }
    });

    this.configService.loadConfig('subscription.aggregateSequelAndCombi').subscribe(config => {
      if( config && config.value) {
        this.aggregateSubscription = config.value === '1' || config.value === 'true';
      }
    });

    this.configService.loadConfig('subscription.showSequelInAggregateMode').subscribe(config => {
      if( config && config.value) {
        this.showSequelSubscriptionInAggregateMode = config.value === '1' || config.value === 'true';
      }
    });

    this.invoiceMethods = this.baseData.getBaseData('invoicemethods');
    this.paymentMethods = this.baseData.getBaseData('paymentmethods');
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.paymentMethodId = null;
      if (this.aggregateSubscription) {
        this.hupSubscriptionsService.getSubscriptionForDetail(params.get('webId'), params.get('backendId')).subscribe(sub => {
          this.sub = sub;
          this.handleSubscription();
        });
      } else {
        this.hupSubscriptionsService.getSubscription(params.get('webId'), params.get('backendId')).subscribe(sub => {
          this.sub = sub;
          this.handleSubscription();
        });
      }
    });
  }

  private handleSubscription() {
    this.paymentService.getPaymentMethodByCode(this.sub?.curPayment?.paymentType?.paymentMethod).subscribe(result => {
      this.paymentMethodId = result;
    });
    const payment = this.paymentMethods?.filter(method => method.key === this.sub?.curPayment?.paymentType?.paymentMethod)[0]?.description;
    const frequency = this.invoiceMethods?.filter(method => method.key === this.sub?.curPayment?.paymentType?.paymentFrequency)[0]?.description;
    this.paymentMethodDesc = payment ?? this.sub.curPayment.paymentType.paymentMethod;
    this.sub!.curPayment!.paymentType!.paymentFrequency = frequency ?? this.sub.curPayment.paymentType.paymentFrequency;
    if (this.sub.validDate.validUntil !== null && this.sub.validDate.validUntil < this.currentDate) {
      this.showTerminationButton = false;
    }
  }

  terminateSubscription() {
    this.router.navigate(['/terminate-subscription/' + this.sub.subId], {queryParamsHandling: "preserve"});
  }

  backToOverview(): void {
    this.navigationService.navigateTo(NavigationCommand.SUBSCRIPTION, null, true);
  }
}
