import {ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {forkJoin, mergeMap, Observable, switchMap} from 'rxjs';
import {LoginService} from './login.service';
import {map, take} from 'rxjs/operators';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {LoginBoxComponent} from './login-box/login-box.component';
import {MatDialog} from '@angular/material/dialog';
import {Globals} from '../../globals';
import {RedirectService} from "../../../data/services/redirect.service";
import {isPlatformBrowser} from "@angular/common";
import {ConfigService} from "../../services/config.service";
import {ConfigData} from "../../../data/models/config.model";
import {AuthResponseData} from "./auth-response-data.model";

@Injectable({providedIn: 'root'})
export class LoginGuard implements CanActivate {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private redirectService: RedirectService,
    private dialog: MatDialog,
    private configService: ConfigService,
    @Inject(PLATFORM_ID) private platformId: Object) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
  {
    return forkJoin({
      useId: this.useIdentification(),
      auth: this.loginService.getAuthData().pipe(take(1))
    }).pipe(map(res => this.redirectTo(res.useId, res.auth, route, state)));
  }

  private redirectTo(useIdentification: boolean, user: AuthResponseData, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree
  {
    const isAuth = !!user;
    const useLandingPage = Globals.USE_LANDING_PAGE;
    if (isAuth) {
      return true;
    }

    switch (state.url) {
      case '/complaint':
        return this.router.createUrlTree(['/complaint-without-login']);
      case '/contact':
        return this.router.createUrlTree(['/contact-without-login']);
      case '/sub-change':
        if (useIdentification) {
          return this.router.createUrlTree(['/sub-change-without-login']);
        }
        break;
    }

    if (isPlatformBrowser(this.platformId)) {
        this.configService.loadConfig('sso.usage').pipe(map(cfgData => {
          if (cfgData.value.toUpperCase().indexOf('OAUTHHASSO') === 0) {
        return this.router.createUrlTree(['/login'], {queryParams: {returnUrl: state.url}});
      } else {
        this.dialog.open(LoginBoxComponent, {data: {url: state.url}, panelClass: 'SSOloginBox'});
      }
        }));
    } else {
      this.redirectService.redirect('/login?returnUrl=' + state.url.replace('/', ''));
    }

    if (useLandingPage) {
      return;
    } else {
      return this.router.createUrlTree(['/']);
    }
  }

  private useIdentification(): Observable<boolean> {
    return this.configService.loadConfig('withoutLogin.useIdentificationForm').pipe(map(config => {
      return config && (config.value === 'true' || config.value === '1');
    }));
  }
}
