import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, first, forkJoin, Observable, of, switchMap} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {catchError, map} from 'rxjs/operators';
import {UserData} from '../../../data/models/user-data.model';
import {HupSubscriptionsService} from '../../../data/services/hup-subscriptions.service';
import {BaseDataService} from '../../services/base-data.service';
import {ComplaintService} from '../../../data/services/complaint.service';
import {MoveService} from '../../../data/services/move.service';
import {PaymentService} from '../../../data/services/payment.service';
import {CookieService} from 'ngx-cookie';
import {AuthResponseData} from './auth-response-data.model';
import {RedirectService} from "../../../data/services/redirect.service";
import {isPlatformBrowser, isPlatformServer} from "@angular/common";
import {LandingPageService} from "../../../data/services/landing-page.service";
import {ajax} from "rxjs/ajax";
import {LogonStatusData} from "./logon-status-data.model";
import {ConfigService} from "../../services/config.service";
import {SsoConfiguration} from "./sso-configuration";
import {OtlcData} from "./otlc-data";

@Injectable({providedIn: 'root'})
export class LoginService {
  private static readonly COOKIE_NAME: string  = 'webabo-auth-cookie';

  userData: UserData;
  isChildUser$ = new BehaviorSubject<boolean>(null);
  private authData = new BehaviorSubject<AuthResponseData>(null);
  private tokenExpirationTimer: any;

  constructor(private http: HttpClient,
              private route: ActivatedRoute,
              private router: Router,
              private baseDataService: BaseDataService,
              private complaintService: ComplaintService,
              private hupSubscriptionsService: HupSubscriptionsService,
              private moveService: MoveService,
              private paymentService: PaymentService,
              private cookieService: CookieService,
              private redirectService: RedirectService,
              private landingPageService: LandingPageService,
              private configService: ConfigService,
              @Inject(PLATFORM_ID) private platformId: Object
  ) {
  }

  public getSsoConfiguration(): Observable<SsoConfiguration> {
    const obsSsoUsage = this.configService.loadConfig('sso.usage');
    const obsClientId = this.configService.loadConfig('sso.client.webabo.id');
    const obsReturnUrl = this.configService.loadConfig('sso.client.webabo.returnUrl');
    const obsSsoHost = this.configService.loadConfig('sso.server.host');

    return forkJoin([obsSsoUsage, obsClientId, obsReturnUrl, obsSsoHost]).pipe(
      map(([confSsoUsage, confClientId, confReturnUrl, confSsoHost ]) => {
        const ssoConf: SsoConfiguration = {
          usage: confSsoUsage.value,
          clientId: confClientId.value,
          returnUrl: confReturnUrl.value,
          ssoHost: confSsoHost.value
        }
        return ssoConf;
      })
    );
  }

  checkStatus() {
    if (isPlatformBrowser(this.platformId)) {
      this.getAuthData().pipe(first()).subscribe((authData) => {
        const isAuthenticated = !!authData && (authData.token !== null);

        // if child-user state is unknown we need to force status check to retrieve this information
        let childUserState;
        this.isChildUser$.pipe(map(flag => childUserState = flag)).subscribe();

        if (!isAuthenticated || childUserState === null) {
          this.getSsoConfiguration().subscribe(ssoConf => {
            if (ssoConf.usage?.toUpperCase().startsWith('OAUTHHASSO')) {
              const url = ssoConf.ssoHost + '/auth/status'
                + '?client_id=' + ssoConf.clientId
                + '&redirect_uri=' + encodeURI(ssoConf.returnUrl);

              const logonStatus = ajax({
                url: url,
                method: 'GET',
                withCredentials: true
              }).pipe(
                map(status => LogonStatusData.of(status.response)),
                catchError(error => {
                  console.error('logon-status-error: ', error);
                  return of(null);
                })
              );

              logonStatus.pipe(first()).subscribe(logonStatusData => {
                const code = logonStatusData.code;
                this.isChildUser$.next(!!logonStatusData.parentUserUuid);
                if (code != null) {
                  this.loginByCode(code).subscribe();
                }
              });
            }
          });
        }
      });
    }
  }

  login(email: string, password: string, route?: string): Observable<any> {
    return this.http.post<AuthResponseData>('/webabo/auth/login', {
        username: email,
        password
      }, {headers: {'Content-Type': 'application/json'}}
    ).pipe(map(authResponseData => {
      if (authResponseData.token?.length) {
        authResponseData.expiration_date = new Date(new Date().getTime() + (authResponseData.timeToLive * 1000));
        this.authData.next(authResponseData);
        this.cookieService.putObject(LoginService.COOKIE_NAME, authResponseData, {expires: authResponseData.expiration_date});
        this.autoLogout(authResponseData.expiration_date);
        this.autoRefresh((authResponseData.timeToLive - 10) * 1000);
        if (route) {
          this.redirectService.redirect(route);
        }
      }
      return authResponseData;
    }));
  }

  loginByCode(code: string, route?: string): Observable<any> {
    return this.http.post<AuthResponseData>('/webabo/auth/loginByCode?code=' + code, null).pipe(map(authResponseData => {
      if (authResponseData.token !== '0') {
        authResponseData.expiration_date = new Date(new Date().getTime() + (authResponseData.timeToLive * 1000));
        this.authData.next(authResponseData);
        this.cookieService.putObject(LoginService.COOKIE_NAME, authResponseData, {expires: authResponseData.expiration_date});
        this.autoLogout(authResponseData.expiration_date);
        this.autoRefresh((authResponseData.timeToLive - 10) * 1000);
        if (route) {
          this.redirectService.redirect(route);
        }
      } else {
        return authResponseData;
      }
    }));
  }

  loginByOtlc(code: string): void {
    this.getSsoConfiguration().subscribe(ssoConf => {
      if (ssoConf.usage.toUpperCase().startsWith('OAUTHHASSO')) {
        const url = ssoConf.ssoHost + '/auth/otlc?code=' + code;
        const otlcResponse = ajax({
          url: url,
          method: 'GET',
          withCredentials: true
        }).pipe(
          map(otlc => OtlcData.of(otlc.response).userId),
          catchError(error => {
            console.error('otlc-error: ', error);
            return of(null);
          })
        );

        otlcResponse.pipe(first()).subscribe(userId => {
          if (userId != null) {
            this.checkStatus();
          }
        });
      }
    });
  }

  autoLogin(): void {
    const userData: AuthResponseData = AuthResponseData.of(this.cookieService.getObject(LoginService.COOKIE_NAME));
    if (userData) {
      try {
        const expirationDuration = new Date(userData.expiration_date);
        const durationTime = expirationDuration.getTime();
        const newTime = new Date().getTime();
        if (durationTime > newTime) {
          this.authData.next(userData);
          this.autoLogout(expirationDuration);
          this.autoRefresh((userData.timeToLive - 10) * 1000);
        } else {
          this.logout();
        }
      } catch( error ) {
        console.log(error);
      }
    }
  }

  logout(): void {
    // zwischengespeicherte Nutzerdaten löschen
    this.resetData();
    localStorage.clear();
    this.paymentService.resetData();
    this.hupSubscriptionsService.resetData();
    this.moveService.resetData();
    this.complaintService.resetData();
    this.landingPageService.resetData();

    this.cookieService.remove(LoginService.COOKIE_NAME);
    if (this.tokenExpirationTimer) {
      clearTimeout(this.tokenExpirationTimer);
    }
    this.tokenExpirationTimer = null;

    this.getSsoConfiguration().subscribe(ssoConf => {
      if (ssoConf.usage.toUpperCase().startsWith('OAUTHHASSO')) {
        const logoutUrl = ssoConf.ssoHost + '/auth/logout?client_id=' + ssoConf.clientId;
        ajax({
          url: logoutUrl,
          method: 'GET',
          responseType: 'json',
          withCredentials: true
        }).pipe(
          catchError(error => {
            console.error('logout-error: ', error);
            // ggf. User informieren !?
            return of(null);
          })
        ).subscribe(() => {
          this.router.navigate(['/'])
            .then(() => null); // muss man hier etwas anderes machen ??
        });
      } else {
        this.router.navigate(['/'])
          .then(() => null); // muss man hier etwas anderes machen ??
      }
    });
  }

  autoLogout(timeout: Date): void {
    const time = timeout.getTime() - new Date().getTime();

    if(isPlatformBrowser(this.platformId)) {
      this.tokenExpirationTimer = setTimeout(() => {
        this.logout();
      }, time);
    }
  }

  refresh(): Observable<any> {
    return this.http.post<AuthResponseData>('/webabo/auth/refresh?token=' + this.authData.value.refreshToken, null)
      .pipe(map(authResponseData => {
      if (authResponseData.code !== '') {
        authResponseData.expiration_date = new Date(new Date().getTime() + authResponseData.timeToLive * 1000);
        this.authData.next(authResponseData);
        this.cookieService.putObject(LoginService.COOKIE_NAME, authResponseData, {expires: authResponseData.expiration_date});
        this.autoLogout(authResponseData.expiration_date);
        this.autoRefresh((authResponseData.timeToLive - 10) * 1000);
      } else {
        return authResponseData;
      }
    }));
  }

  autoRefresh(timeout: number): void {
    if(isPlatformBrowser(this.platformId)) {
      this.tokenExpirationTimer = setTimeout(() => {
        this.refresh().subscribe();
      }, timeout);
    }
  }

  getUserData(): Observable<UserData> {
    if (this.userData) {
      return of(this.userData);
    }
    return this.http.get<UserData>('/webabo/users').pipe(map(userData => {
      this.baseDataService.handleAddress(userData.address);
      this.baseDataService.handlePerson(userData.person);
      this.userData = userData;
      return this.userData;
    }));
  }

  resetData(): void {
    this.authData.next(null);
    this.isChildUser$.next(null);
    this.userData = null;
  }

  getAuthData(): Observable<AuthResponseData> {
    if (!this.authData.getValue()) {
      this.autoLogin();
    }
    return this.authData;
  }

  getPasswordComplexityInfo(): Observable<any> {
    return this.getSsoConfiguration().pipe(
      switchMap(ssoConf => {
        if (isPlatformServer(this.platformId) || ssoConf.usage?.toUpperCase().indexOf('OAUTHHASSO') != 0) {
          return of(null);
        } else {
          return ajax.getJSON(ssoConf.ssoHost + '/util/password-complexity').pipe(
            catchError(() => of(null))
          );
        }
      })
    );
  }}
