<div class="row">
  <div class="col-12">
    <app-general-errors [errors]="errorsArr"></app-general-errors>
    <div *ngIf="isLoading" style="text-align: center;">
      <app-loading-spinner></app-loading-spinner>
    </div>
  </div>
</div>
<mat-stepper linear #stepper [orientation]="(stepperOrientation | async)!">
  <!-- Typ, Datum -->
  <mat-step [stepControl]="subChangeTypeForm" editable>
    <form [formGroup]="subChangeTypeForm" class="mt-3">
      <ng-container *ngIf="!isMobile">
        <ng-template matStepLabel>
          <div [innerHTML]="translationService.getTranslation('step_subChangeType')"></div>
        </ng-template>
      </ng-container>
      <div *ngIf="isMobile" class="mx-1">
        <h2 [innerHTML]="translationService.getTranslation('step_subChangeType')"></h2>
      </div>
      <mat-form-field (click)="picker.open()" formGroupName="validDate">
        <mat-label [innerHTML]="translationService.getTranslation('period')"></mat-label>
        <mat-date-range-input [rangePicker]="picker" [min]="minDate">
          <input matStartDate formControlName="validFrom" readonly placeholder="Startdatum">
          <input matEndDate formControlName="validUntil" readonly placeholder="Enddatum">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker [touchUi]="isMobile" #picker disabled="false"></mat-date-range-picker>
        <mat-error
          *ngIf="subChangeTypeForm.get('validDate').hasError('message')">
          {{subChangeTypeForm.get('validDate').getError('message')}}
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label [innerHTML]="translationService.getTranslation('subchange_type')"></mat-label>
        <mat-select formControlName="changeType" required [value]="subChangeTypeForm.get('changeType').value" #selection>
          <mat-option *ngFor="let subChangeType of subChangeTypes"
                      [value]="subChangeType">
            {{translationService.getTranslation('label_' + subChangeType.code + '_description')}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div>
        <button (click)="getPriceForSubChange(stepper)"
                mat-raised-button color="primary" matStepperNext
                [disabled]="!subChangeTypeForm.valid"
                [innerHTML]="translationService.getTranslation('button_next')">
        </button>
      </div>
    </form>
  </mat-step>

  <!-- Typ:Weiterleitung -->
  <mat-step [stepControl]="forwardDataForm" editable
            *ngIf="subChangeTypeForm.get('changeType').value.code === 'FORWARD'">
    <form [formGroup]="forwardDataForm" class="mt-3">
      <ng-container *ngIf="!isMobile">
        <ng-template matStepLabel><div [innerHTML]="translationService.getTranslation('step_subChangeData')"></div></ng-template>
      </ng-container>
      <div *ngIf="isMobile" class="mx-1">
        <h2 [innerHTML]="translationService.getTranslation('step_subChangeData')"></h2>
      </div>

      <app-hup-subscription-address [showActivateCheckbox]="false"
                                    [controlGroup]="forwardDataForm">
      </app-hup-subscription-address>

      <mat-checkbox *ngIf="enableAdditionalExemplar" formControlName="additional" class="text-wrap">
        <div [innerHTML]="translationService.getTranslation('subChange_additionalIssue')"></div>
      </mat-checkbox>
      <div>
        <button class="me-3" mat-raised-button matStepperPrevious
                [innerHTML]="translationService.getTranslation('button_back')"></button>
        <button *ngIf="!priceCheckFinished || hasFormChanges()"
                (click)="getPriceForType(changeable)"
                mat-raised-button color="primary"
                [innerHTML]="translationService.getTranslation('button_check_price')"
                [disabled]="!forwardDataForm.valid"></button>
        <button *ngIf="priceCheckFinished  && !hasFormChanges()"
                matStepperNext
                mat-raised-button color="primary"
                [disabled]="isLoading"
                [innerHTML]="translationService.getTranslation('button_next')"></button>
      </div>

      <div class="row" *ngIf="priceCheckFinished">
        <div class="col-12">
          <app-general-warning [warnings]="warningsArr"></app-general-warning>
          <div *ngIf="isLoading" style="text-align: center;">
            <app-loading-spinner></app-loading-spinner>
          </div>
        </div>
      </div>
    </form>
  </mat-step>

  <!--Dieser Block ist erstmals entfallen, da es nur eine Art von Spende ermöglicht wurde. Kann es sein, dass er später benutzt wird?-->
  <!-- Typ:Donation -->
  <!--  <mat-step [stepControl]="donationDataForm" editable-->
  <!--            *ngIf="subChangeTypeForm.get('changeType').value.code === 'DONATION'">-->
  <!--    <form [formGroup]="donationDataForm" class="mt-3">-->
  <!--      <ng-container *ngIf="!isMobile">-->
  <!--        <ng-template matStepLabel>{{translationService.getTranslation('step_subChangeData')}}</ng-template>-->
  <!--      </ng-container>-->
  <!--      <div *ngIf="isMobile" class="col-12 mx-1">-->
  <!--        <h2 [innerHTML]="translationService.getTranslation('step_subChangeData')"></h2>-->
  <!--      </div>-->
  <!--      TODO: DONATION-->
  <!--      <div>-->
  <!--        <button class="me-3" mat-raised-button matStepperPrevious [innerHTML]="translationService.getTranslation('button_back')"></button>-->
  <!--        <button (click)="getPriceForSubChange(stepper)" mat-raised-button color="primary" matStepperNext [innerHTML]="translationService.getTranslation('button_next')"></button>-->
  <!--      </div>-->
  <!--    </form>-->
  <!--  </mat-step>-->

  <!-- Typ:Collection -->
  <mat-step [stepControl]="collectDataForm" editable
            *ngIf="subChangeTypeForm.get('changeType').value.code === 'COLLECT'">
    <form [formGroup]="collectDataForm" class="mt-3">
      <ng-container *ngIf="!isMobile">
        <ng-template matStepLabel [innerHTML]="translationService.getTranslation('step_subChangeData')"></ng-template>
      </ng-container>
      <div *ngIf="isMobile" class="col-12 mx-1">
        <h2 [innerHTML]="translationService.getTranslation('step_subChangeData')"></h2>
      </div>
      TODO: COLLECT
      <div>
        <button class="me-3" mat-raised-button matStepperPrevious
                [innerHTML]="translationService.getTranslation('button_back')"></button>
        <button (click)="getPriceForSubChange(stepper)" mat-raised-button color="primary" matStepperNext
                [innerHTML]="translationService.getTranslation('button_next')"></button>
      </div>
    </form>
  </mat-step>

  <!-- Zusammenfassung -->
  <mat-step>
    <form [formGroup]="subChangeTypeForm" class="mt-3">
      <div *ngIf="saving" style="text-align: center;">
        <app-loading-spinner></app-loading-spinner>
      </div>
      <ng-container *ngIf="!isMobile">
        <ng-template matStepLabel><div [innerHTML]="translationService.getTranslation('step_summary')"></div> </ng-template>
      </ng-container>
      <div *ngIf="isMobile" class="col-12 mx-1">
        <h2 [innerHTML]="translationService.getTranslation('step_summary')"></h2>
      </div>

      <h5 [innerHTML]="translationService.getTranslation('step_subChangeType')"></h5>
      <div class="container-fluid p-0">
        <div class="row m-0">
          <div class="col-6" [innerHTML]="translationService.getTranslation('step_subChangeType')"></div>
          <div class="col-6">{{subChangeTypeForm.get('changeType').value.description}}</div>
        </div>
        <div class="row m-0">
          <div class="col-6" [innerHTML]="translationService.getTranslation('startdate')"></div>
          <div class="col-6">{{subChangeTypeForm.get('validDate.validFrom').value | date:
            translationService.getTranslation('dateFormat')}}
          </div>
        </div>
        <div class="row m-0">
          <div class="col-6" [innerHTML]="translationService.getTranslation('enddate')"></div>
          <div class="col-6">{{subChangeTypeForm.get('validDate.validUntil').value | date:
            translationService.getTranslation('dateFormat')}}
          </div>
        </div>
      </div>
       <mat-divider class="mb-1 mt-3"></mat-divider>

      <ng-container *ngIf="subChangeTypeForm.get('changeType').value.code === 'FORWARD'">
        <h5 [innerHTML]="translationService.getTranslation('step_subChangeData')"></h5>
        <div class="container-fluid p-0">
          <div class="row m-0">
            <div class="col-6" [innerHTML]="translationService.getTranslation('address_forward')"></div>
            <div class="col-6">
              <ul class="list-unstyled">
                <li>
                  {{forwardDataForm.get('person.salutation').value.description}}
                  {{forwardDataForm.get('person.title').value.description}}
                  {{forwardDataForm.get('person.firstName').value}} {{forwardDataForm.get('person.lastName').value}}
                </li>
                <li>
                  {{forwardDataForm.get('address.street').value}} {{ userHouseNo | houseNoPipe }}, {{ forwardDataForm.get('address.extraLine')?.value ? forwardDataForm.get('address.extraLine')?.value + ',' : '' }}
                  {{forwardDataForm.get('address.zipcode').value }} {{forwardDataForm.get('address.city').value }}, {{forwardDataForm.get('address.country').value.description}}
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="enableAdditionalExemplar" class="row m-0">
            <div class="col-6" [innerHTML]="translationService.getTranslation('subchange_additional')"></div>
            <div class="col-6">
              <mat-icon *ngIf="forwardDataForm.get('additional').value">done</mat-icon>
              <mat-icon *ngIf="!forwardDataForm.get('additional').value">clear</mat-icon>
            </div>
          </div>
        </div>
        <div class="row m-0">
          <div class="col-6" [innerHTML]="translationService.getTranslation('forwarding_summary.new_price')"></div>
          <div *ngIf="offerPrice?.price else priceCheckError" class="col-6"
               [innerHTML]="offerPrice?.price ? offerPrice.price + ' ' + offerPrice?.currency : zeroPrice">
          </div>
          <ng-template #priceCheckError>
            <div class="col-6" [innerHTML]="translationService.getTranslation('forwarding_summary.unknown_price')"></div>
          </ng-template>
        </div>
      </ng-container>

      <div class="mt-3">
        <button class="me-3" mat-raised-button matStepperPrevious [innerHTML]="translationService.getTranslation('button_back')">
        </button>
        <button *ngIf="changeable && !isLoading" [hidden]="saving" mat-raised-button color="primary"
                (click)="onSend(stepper)" [innerHTML]="translationService.getTranslation('button_send')">
        </button>
      </div>
    </form>
  </mat-step>

</mat-stepper>

<div class="mt-3 float-end">
  <button *ngIf="showBackToOverviewButton"
          mat-raised-button (click)="backToOverview()" [innerHTML]="translationService.getTranslation('button_back_to_overview')"></button>
</div>
