import {Component, OnInit} from '@angular/core';
import {TranslationService} from '../../core/services/translation.service';
import {HupSubscriptionsService} from '../../data/services/hup-subscriptions.service';
import {ActivatedRoute} from '@angular/router';
import {HupSubscription} from '../../data/models/subscription.model';
import {NavigationCommand, NavigationService} from "../../data/services/navigation.service";
import {LoginService} from "../../core/components/login/login.service";
import {ConfigService} from "../../core/services/config.service";

@Component({
  selector: 'app-hup-subscription',
  templateUrl: './hup-subscription.component.html',
  styleUrls: ['./hup-subscription.component.scss']
})
export class HupSubscriptionComponent implements OnInit {
  subs: HupSubscription[] = [];
  isLoading: boolean;
  errorsArr: string[] = [];
  aggregateSubscription: boolean = false;

  constructor(public translationService: TranslationService,
              private hupSubscriptionsService: HupSubscriptionsService,
              private route: ActivatedRoute,
              private navigationService: NavigationService,
              private loginService: LoginService,
              private configService: ConfigService) {
    this.isLoading = true;
    this.configService.loadConfig('subscription.aggregateSequelAndCombi').subscribe(config => {
      if (config && config.value) {
        this.aggregateSubscription = config.value === '1' || config.value === 'true';
      }
    });
  }

  ngOnInit(): void {
    this.loginService.getAuthData().subscribe((authData) => {
      this.isLoading = true;
      if (!!authData && (authData.token !== null)) {
        if (this.aggregateSubscription) {
          this.hupSubscriptionsService.getSubscriptionsGroupedAlsoTerminated().subscribe(subs => {
            this.handleSubscriptions(subs);
          }, error => {
            this.isLoading = false;
            this.errorsArr.push(this.translationService.getTranslation('error_subscription_init'));
          });
        } else {
          this.hupSubscriptionsService.getSubscriptionsAlsoTerminated().subscribe(subs => {
            this.handleSubscriptions(subs);
          }, error => {
            this.isLoading = false;
            this.errorsArr.push(this.translationService.getTranslation('error_subscription_init'));
          });
        }
      } else {
        // Fehler beim Laden der Abos!
        this.isLoading = false;
        this.errorsArr.push(this.translationService.getTranslation('error_subscription_init'));
      }
    });
  }

  private handleSubscriptions(subs: HupSubscription[]) {
    this.isLoading = false;
    this.subs = subs;
    if (subs && subs.length === 1) {
      this.aboDetails(this.subs[0]);
    }
  }

  cancelOrder(sub): void {
    alert('cancelOrder');
  }

  // Unterscheiden sich die irgendwie?
  // inProcessDetails(sub): void {
  //   this.router.navigate([+sub.webId + '/' + +sub.backendId], {relativeTo: this.route});
  // }
  aboDetails(sub): void {
    this.navigationService.navigateTo(NavigationCommand.SUBSCRIPTION, [sub.webId, sub.backendId]);
  }

  // changeAboDetails(sub): void {
  //   alert('changeAboDetails');
  // }

}
