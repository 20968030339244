import {AfterViewInit, Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {LoginService} from './core/components/login/login.service';
import {Globals} from './core/globals';
import {isPlatformBrowser} from "@angular/common";
import {ConfigService} from "./core/services/config.service";
import {CssService} from "./core/services/css.service";
import {CssPropertiesEnum} from "./core/models/css-properties.enum";
import {VHostService} from "./core/services/v-host.service";
import {WebSocketService, WebSocketTopics} from "./core/services/web-socket.service";
import {LandingPageService} from "./data/services/landing-page.service";
import {OrderTrackingService} from "./core/services/order-tracking.service";
import {GtmEnum} from "./core/models/gtm.enum";

@Component({
    selector: 'app-hup-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
    title = 'webabo';
    useLandingPage = Globals.USE_LANDING_PAGE;
    useBreadCrumb = Globals.USE_BREADCRUMB;
    horizontalNavigation = true;
    cssPropertiesEnum = CssPropertiesEnum;

    hideNavigation = false;
    showNavigation: boolean = true;

    constructor(private router: Router,
                private loginService: LoginService,
                private configService: ConfigService,
                private cssService: CssService,
                public vHostService: VHostService,
                private websocketService: WebSocketService,
                private route: ActivatedRoute,
                private landingPageService: LandingPageService,
                private orderTrackingService: OrderTrackingService,
                @Inject(PLATFORM_ID) private platformId: Object) {
        if (isPlatformBrowser(platformId) && window['dataLayer']) {
          dataLayer = window['dataLayer'];
        }
        this.configService.loadConfig('horizontalNavigation').subscribe(config => {
            if (config && config.value) {
                this.horizontalNavigation = (config.value === '1');
            }
        });
        this.route.queryParams.subscribe(params => {
            this.hideNavigation = !!params['hideNavigation'];
        });

        this.configService.loadConfig('removeNavigation').subscribe(config => {
            if (config) {
                this.hideNavigation = (config.value === 'true');
            }
        });

        this.configService.loadConfig('sso.usage').subscribe();
        this.configService.loadConfig('sso.client.webabo.id').subscribe();
        this.configService.loadConfig('sso.client.webabo.returnUrl').subscribe();
        this.configService.loadConfig('sso.server.host').subscribe();

        this.router.events.forEach(item => {
            if (item instanceof NavigationEnd) {
                const gtmTag = {
                    event: 'page',
                    pageName: item.url
                };
                this.orderTrackingService?.pushEvent(GtmEnum.SITE_NAVIGATION, gtmTag);
            }
        });

        loginService.isChildUser$.subscribe(childUserState => {
          if (childUserState) {
            this.router.navigateByUrl('/child-user-info', {skipLocationChange: true});
          }
        });
    }

    ngOnInit(): void {
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            if (isPlatformBrowser(this.platformId)) {
                window.scrollTo(0, 0);
            }
        });

        this.loginService.autoLogin();
        this.loginService.checkStatus();
        this.orderTrackingService.generateTransactionId();

        this.websocketService.getObservable(WebSocketTopics.configChanged).subscribe(data => {
            this.configService.clearConfig();
            this.landingPageService.resetData();
        });

        this.websocketService.getObservable(WebSocketTopics.clearCache).subscribe(data => {
            this.loginService.logout();
        });
    }

    ngAfterViewInit(): void {
        this.cssService?.setStyles()?.subscribe();
        this.vHostService.vHost?.css?.forEach(cssStyle => {
            if (cssStyle && cssStyle.key === 'css.customCss') {
                const styles = cssStyle.value;
                this.cssService.appendCss(styles);
            }
        });
    }
}
