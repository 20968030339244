import {Component, Input} from '@angular/core';
import {TranslationService} from "../../../../core/services/translation.service";
import {HupSubscription} from "../../../../data/models/subscription.model";

@Component({
  selector: 'app-product-variant-period-block',
  templateUrl: './product-variant-period-block.component.html',
  styleUrls: ['./product-variant-period-block.component.css']
})
export class ProductVariantPeriodBlockComponent {

  @Input() subscription: HupSubscription
  today: Date = new Date();

  constructor(public translationService: TranslationService) {
  }

}
