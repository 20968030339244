import {AbstractControl, AsyncValidatorFn, ValidationErrors} from '@angular/forms';
import {Observable, of, timeout} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {TranslationService} from './services/translation.service';

export class CustomValidators {
  static validateIBAN(http: HttpClient, translationService: TranslationService, swiftBIC: AbstractControl = null, bankName: AbstractControl = null): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      if (swiftBIC) { swiftBIC.reset(translationService.getTranslation('filled_automatically')); }
      if (bankName) { bankName.reset(translationService.getTranslation('filled_automatically')); }
      //TODO Erst prüfen wenn IBAN >= 15 ist
      return http.get<any>('/webabo/bank/iban/' + control.value).pipe(
        timeout(15000),
        // andersherum würde map nach catchError trotzdem ausgeführt! (gibt es evtl. eine pipe Function, die nur bei success greift?)
        map( (response) => {
          if (swiftBIC) { swiftBIC.patchValue(response.swiftBIC); }
          if (bankName) { bankName.patchValue(response.bankName); }
          return null;
        }),
        catchError( () => {
          return of({message: translationService.getTranslation('error_invalid_iban')});
        }),
      );
    };
  }

  static checkEmailAddress(http: HttpClient, translationService: TranslationService): AsyncValidatorFn | null {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      if (control && control.value) {
        return CustomValidators.checkIfEmailExists(control.value, http, translationService);
      } else {
        return of(null);
      }
    };
  }

  static checkIfEmailExists(email: string, http: HttpClient, translationService: TranslationService): Observable<any> {
    const httpParams = new HttpParams().append("emailAddress", email)
    return http.post<any>('/webabo/auth/check-email', httpParams).pipe(
      timeout(15000),
      // andersherum würde map nach catchError trotzdem ausgeführt! (gibt es evtl. eine pipe Function, die nur bei success greift?)
      map((response) => {
        if (response) {
          throw new Error()
        } else {
          return null;
        }
      }),
      catchError(() => {
        return of({message: translationService.getTranslation('error_email_exists')});
      }),
    );
  }
  static checkCompletePhonenumber(translationService: TranslationService, phone: AbstractControl = null, phonecode: AbstractControl = null): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors> => {
    //beide nicht gefüllt oder beide gefüllt
    if((phone.value?.length == 0 && phonecode.value?.length == 0)||( phone.value?.length > 0 && phonecode.value?.length > 0) || (phone.value?.length == undefined && phonecode.value?.length == undefined)) {
      if(!phone.valid) {
        phone.updateValueAndValidity({onlySelf:true, emitEvent:true})
      }
      if(!phonecode.valid) {
        phonecode.updateValueAndValidity({onlySelf:true, emitEvent:true})
      }
      return null;
    } else {
      //nur 1 gefüllt (macht formular ungültig)
      return of({message: translationService.getTranslation('identification_wrong_phone')});
    }
  }
  }
}
