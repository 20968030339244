<div class="row mb-3" id="delayHeader">
  <div class="col-12">
    <h4 [innerHTML]="translationService.getTranslation('delay_intro')"></h4>
  </div>
  <div [innerHTML]="translationService.getTranslation('delay_description')" class="col-12">
  </div>
</div>
<div class="row">
  <div class="col-12">
<app-hup-subscription-address [askForBirthday]="false"
                              [askForCountry]="false"
                              [askForEmail]="false"
                              [showAddressExt1]="false"
                              [askForPerson]="false"
                              [controlGroup]="delayForm"
                              [showActivateCheckbox]="false"></app-hup-subscription-address>
  </div>
  <div class="col-12">
<!--zustellung prüfen button-->
<button mat-raised-button color="primary" (click)="checkDelay()" [disabled]="!delayForm.valid"
        [innerHTML]="translationService.getTranslation('delay_check')" ></button>
  </div>
</div>

<div *ngIf="delaystate == DelayComponentEnum.NODELAY">
  <!--  nwenn keine störungsmeldung vorliegt
  -->
  <div class="row mt-3 mb-3">
    <div [innerHTML]="translationService.getTranslation('delay_toComplaintForm')" class="col-12 mb-2">
    </div>
  </div>
  <button mat-raised-button color="primary" (click)="navigateToComplaint()"
          [innerHTML]="translationService.getTranslation('button_new_complaint')"
          ></button>
</div>

<div *ngIf="delaystate == DelayComponentEnum.DELAY">
  <!--  falls Spätmeldung gefunden und weniger Meldungen als Bezirke-->
  <div [innerHTML]="translationService.getTranslation('delay_delay')" class="row mt-3 mb-3">
  </div>
  <ul>
    <li *ngFor='let item of messageText'> {{ item }}</li>
  </ul>
</div>

<div *ngIf="delaystate == DelayComponentEnum.DELAYINVALIDDISTRICT">
  <!--  falls bezirk nicht gefunden-->
  <div [innerHTML]="translationService.getTranslation('delay_invalid_district')" class="row mt-3 mb-3">
  </div>
  <button mat-raised-button color="primary" (click)="navigateToComplaint()"
          [innerHTML]="translationService.getTranslation('button_new_complaint')"
          ></button>
</div>

<div *ngIf="delaystate == DelayComponentEnum.POSSIBLEDELAY">
  <!--Zustellung möglicherweise zu spät, mehr Bezirke als Meldungen-->
  <div [innerHTML]="translationService.getTranslation('delay_possible_delay')" class="row mt-3 mb-3">
  </div>
  <ul>
    <li *ngFor='let item of messageText'> {{ item }}</li>
  </ul>
</div>

