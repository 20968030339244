import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslationService} from '../../core/services/translation.service';
import {NavigationCommand, NavigationService} from '../../data/services/navigation.service';
import {LogisticServiceService} from '../../data/services/logistic-service.service';
import {MessageSearchResult} from '../../data/models/message-search-result';
import {DatePipe} from '@angular/common';
import {AddressSearch} from '../../data/models/address-search.model';
import {AddressSearchService} from '../../core/services/address-search.service';
import {DelayComponentEnum} from './delay.component.enum';

@Component({
  selector: 'delay', templateUrl: './delay.component.html', styleUrls: ['./delay.component.scss']
})
export class DelayComponent implements OnInit {
  @ViewChild('houseNumber') houseNo: string;
  @ViewChild('matCities') city: string;
  @ViewChild('matStreetNames') street: string;
  @ViewChild('matZipCodes') zipCode: string;

  delaystate: DelayComponentEnum;
  delayForm: FormGroup;

  public messageText: string [] = [];
  messages: MessageSearchResult[] = [];
  bezirk: string = '';

  constructor(public translationService: TranslationService, private formBuilder: FormBuilder, private navigationService: NavigationService, private logisticServiceService: LogisticServiceService, private addressSearchService: AddressSearchService, private datePipe: DatePipe,) {

    this.delayForm = this.formBuilder.group({
      address: this.formBuilder.group({
        country: [''],
        zipcode: ['', Validators.required],
        city: ['', Validators.required],
        street: ['', Validators.required],
        houseNo: ['', Validators.required],
        extraLine: [''], //
        houseNoExt: [''],
        stiege: [''],
        stock: [''],
        tuer: [''],
        district: [''],
      }), person: this.formBuilder.group({
        title: [''], salutation: [''], firstName: [''], lastName: [''], initials: [''], middlename: [''],
      }),
    });
  }

  ngOnInit(): void {
  }


  navigateToComplaint(): void {
    this.navigationService.navigateTo(NavigationCommand.COMPLAINT);
  }

  buildDelayMessage(messageSearchResult: MessageSearchResult): string {
    return this.datePipe.transform(messageSearchResult.messageDate, 'dd.MM.yyyy') + ': ' + messageSearchResult.classification.displayText + '. ' + messageSearchResult.messageText.text + ' (' + this.translationService.getTranslation('logistic_messages.valid_until') + this.datePipe.transform(messageSearchResult.delay.messageDelaysUntil, 'dd.MM.yyyy') + ')';
  }

  checkDelay() {
    //beide arrays leeren, damit beim erneuten Drücken des Buttons keine "Altlasten" vorhanden sind
    this.messages = [];
    this.messageText = [];
    this.bezirk = '';

    this.zipCode = this.delayForm.get('address.zipcode').getRawValue();
    this.city = this.delayForm.get('address.city').getRawValue();
    this.street = this.delayForm.get('address.street').getRawValue();
    this.houseNo = this.delayForm.get('address.houseNo').getRawValue();

      let newAddress = new AddressSearch(this.street, this.zipCode, this.city, null, null, this.houseNo);

      this.addressSearchService.searchAddress(newAddress, 1).subscribe(searchResult => {
        let bezirk = searchResult[0].districtIds;

        //falls die liste der districtIDs nicht leer ist
        if (bezirk.length != 0) {
          // für jeden bezirk wird gesucht…
          for (const element of bezirk) {
            this.logisticServiceService.getDelayMessages(element).subscribe(messages => {
              this.messages = this.messages.concat(messages);
              if (this.messages.length == 0) {
                //     es gibt keine Spätmeldung
                this.delaystate = DelayComponentEnum.NODELAY;

              } else if (bezirk.length > this.messages.length) {
                // mehr Bezirke als Meldungen
                for (const message of messages) {
                  this.messageText.push(this.buildDelayMessage(message));
                }
                this.delaystate = DelayComponentEnum.POSSIBLEDELAY;
              } else {
                // alles andere
                for (const message of messages) {
                  this.messageText.push(this.buildDelayMessage(message));
                }
                this.delaystate = DelayComponentEnum.DELAY;
              }
            });
          }
          //   bezirk nicht gefunden
        } else {
          this.delaystate = DelayComponentEnum.DELAYINVALIDDISTRICT;
        }
      });

  }

  protected readonly DelayComponentEnum = DelayComponentEnum;
}

