/**
 * Bildet den Status möglicher Verspätungsmeldungen ab
 * DELAY = Spätmeldung gefunden und weniger oder gleich viele Meldungen als Bezirke,
 * DELAYINVALIDDISTRICT = kein Bezirk zugeordnet,
 * POSSIBLEDELAY = Zustellung möglicherweise zu spät, mehr Bezirke als Meldungen
 * NODELAY = Keine Verspätung
 */
export enum DelayComponentEnum {
  DELAY,
  DELAYINVALIDDISTRICT,
  POSSIBLEDELAY,
  NODELAY
}
