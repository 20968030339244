import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {SubChange} from '../models/sub-change.model';
import {SubChangeTypeStatus} from '../models/sub-change-type-status.model';
import {SubChangeTypeEnum} from '../models/sub-change-type.model';
import {DonationPool} from "../models/donation-pool.model";

@Injectable({providedIn: 'root'})
export class SubChangeService {
  constructor(private http: HttpClient) {}

  private subChanges: SubChange[] = [];
  loadedAll = false;

  getSubChanges(subId: number, clientNo: number = -1): Observable<SubChange[]>{
    if (this.loadedAll) {
      return of(this.subChanges);
    }
    else {
      const salt = new Date().getTime();
      return this.http.get<SubChange[]>
      ('/webabo/sub-changes' + (clientNo > 1 ? '/unauthorized' : '') + '?subscriptionId=' + subId + (clientNo > 1 ? '&clientNo=' + clientNo : '' ) + '&' + salt)
        .pipe(tap(subChanges => {
          this.loadedAll = true;
          subChanges.forEach(subChange => {
            this.handleSubChange(subChange);
          });
          this.subChanges = subChanges;
      }));
    }
  }

  getSubChange(webId: number, backendId: number): Observable<SubChange>{
    for (const subChange of this.subChanges) {
      if (subChange.webId === webId && subChange.backendId === backendId) {
        return of(subChange);
      }
    }
    return this.http.get<any>('/webabo/sub-changes/' + webId).pipe(tap(subChange => {
      this.handleSubChange(subChange);
      this.subChanges.push(subChange);
    }));
  }

  sendSubChange(data, unauthorized : boolean): Observable<any> {
    return this.http.post('/webabo/sub-changes' + (unauthorized ? '/unauthorized' : ''), data).pipe(tap( () => {
      this.loadedAll = false;
    }));
  }

  getSubChangeTypes(subId: number): Observable<SubChangeTypeStatus> {
    return this.http.get<SubChangeTypeStatus>('/webabo/sub-changes/actions/' + subId);
  }

  updateSubChange(subChange, subChangeId: number, orderId: number, backendId: number): Observable<any> {
    const url = '/webabo/sub-changes/' + subChangeId + '?backendId=' + backendId + '&subId=' + orderId;
    return this.http.patch<SubChangeTypeStatus>(url, subChange);
  }

  getPriceForSubChange(subChange, unauthorized : boolean): Observable<any> {
    return this.http.post('/webabo/sub-changes/prices' + (unauthorized ? '/unauthorized' : ''), subChange).pipe(map( data => {
      this.loadedAll = false;
      return data;
    }));
  }

  deleteSubChange(webId: number, subscriptionId: number, backendId: number): void {
    const url = 'webabo/sub-changes/' + webId + '?subscriptionId=' + subscriptionId + '&backendId=' + backendId;
    this.http.delete(url).subscribe();
  }

  handleSubChange(subChange): void {
    subChange.changeType = (typeof subChange.changeType  === 'string' || subChange.changeType instanceof String) ? subChange.changeType : SubChangeTypeEnum[subChange.changeType];
  }

  getDonationPools(subscriptionId: number): Observable<DonationPool[]> {
    const url = '/webabo/sub-changes/donation-pools/' + subscriptionId;
    return this.http.get<DonationPool[]>(url);
  }
}
