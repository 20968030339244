import {FormOfPayment} from "./formOfPayment.model";
import {SafeUrl} from "@angular/platform-browser";
import {Bonus} from "./bonus.model";
import {SubExtendedData} from "./sub-extended-data.model";
import {OptIn} from "./optin.model";

export class Offer {
  public combinedOfferList: Offer[]
  public groupSort: number;
  public recno: number;
  public offerPaymentOfferType: FormOfPayment[];
  public priceCalculationActivated: boolean;
  public priceCalculationSummary: string;
  public salesChannel1: string;
  public salesChannel2: string;
  public salesCode: string;
  public description: string;
  public summary: string;
  public additionalInfoText: string;
  public offerGroup: number;
  public price?: number;
  public offerPrice?: {price :number}
  public bonusList?: Bonus[];
  public requiredAddresses: { deliveryAddressRequired: boolean, billingAddressRequired: boolean};
  public product: {
    productCode: string,
    variantCodeList: [
      variantCode: string
    ]
  };
  public image: SafeUrl;
  public additionalPriceDescription: string;
  public offerType: { ePaper: boolean, sample: boolean, donationAllowed: boolean}
  public confirmationText: string;
  public confirmationImage: SafeUrl;
  public requiredAdditionalData: SubExtendedData[];
  public orderChoiceKey: number;
  public optIns: OptIn[];
}
export enum OptInChannel {
  LETTER = 'LETTER',
  PHONE = 'PHONE',
  EMAIL = 'EMAIL'
}

