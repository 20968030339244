export class AddressSearch {
  constructor(
    public streetName: string,
    public zipcode: string,
    public city: string,
    public district: string,
    public districtIds: string,
    public houseNo: string,
  ){}
}
